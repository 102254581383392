import React, { useState } from 'react';
import 'antd/dist/antd.css';
import Footer from './components/Footer'
import Services from './components/Services';
import './index.css'
import logo from './imgs/Mandeville-Entertainment-Logo.png'

function App() {
  let servicesList = [
    {
      name: 'Coaching',
      form: 'https://form.jotform.com/212375753405152',
      formId: '212375753405152'
    },
    {
      name: 'Recording',
      form: 'https://form.jotform.com/212436184154149',
      formId: '212436184154149'
    },
    {
      name: 'Production',
      form: 'https://form.jotform.com/212435459785163',
      formId: '212435459785163'
    },
    {
      name: 'Promotion',
      form: 'https://form.jotform.com/212435580877160',
      formId: '212435580877160'
    },
  ]
  const [services, setServices] = useState(servicesList)
  
  return (
    <div className="App">
      <img width="876" height="522" src={logo} alt="" />

      <Services services={services} />
      <Footer />
    </div>
  );
}

export default App;

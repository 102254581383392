import React from "react";
import "./footer.css";

function Footer() {
  let currentYear = new Date().getFullYear();

  return (
    <div class='footer'>
      <p>&nbsp;</p>
      <p align='center'>
        390 Interlocken Crescent, Suite 350 : Broomfield, Colorado 80021
        :&nbsp;+1.646.568.7600 : media@mandeville.media
      </p>
      <br />
      <p align='center'>
        © {currentYear} Mandeville Entertainment : All rights reserved.
      </p>
    </div>
  );
}

export default Footer;

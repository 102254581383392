import React, { useState } from 'react'
import { Card, Modal } from 'antd';
import { useMediaQuery } from 'react-responsive'
import './services.css'

function Services({ services }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentForm, setCurrentForm] = useState("form");
  const [currentFormId, setCurrentFormId] = useState("id");
  const [currentService, setCurrentService] = useState("");
  const mobile = useMediaQuery({ query: '(max-width: 850px)' });

  const gridStyle = {
    width: '25%',
    alignItems: 'center',
    textAlign: 'center',
  };

  if (mobile) {
    gridStyle.width = '50%';
  }

  const showModal = (name, form, id) => {
    setCurrentService(name)
    setCurrentForm(form)
    setCurrentFormId(id)
    setTimeout(() => {
      setIsModalVisible(true);
    }, 140);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setCurrentService('');
    setCurrentForm('');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="services">

      {console.log(currentForm)}
      {console.log(currentFormId)}
      
      <Card className="card" align="center" title="Artist Services">
      {services.map(service => {
        return (
          <>
            <a><Card.Grid onClick={() => showModal(service.name, service.form, service.formId)} style={gridStyle}>{service.name}</Card.Grid></a>       
          </>
        );
      })}
      
      </Card>
      <Modal okType="default" title={currentService} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <iframe
          id={`JotFormIFrame-${currentFormId}`}
          title="M.E. Contact Form"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src={currentForm}
          frameborder="0"
          style={{
          minWidth: "100%",
          height: "1020px",
          border: "none"}}
          scrolling="no"
        >
        </iframe>
      </Modal>
    </div>
  )
}

export default Services
